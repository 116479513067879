@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  *,
  *:focus {
    @apply outline-none;
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;

    @screen md {
      font-size: 16px;
    }

    @screen xl {
      font-size: 18px;
    }
  }

  body {
    margin: 0;
  }

  input {
    background-color: transparent;
  }

  // class to apply the `gradient` property inside the `<Text />` component
  .text-fill-color-transparent {
    -webkit-text-fill-color: transparent;
  }

  // hide number input indicators
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }

  ::selection {
    background-color: #0a93ec;
    color: #fff;
  }

  .dark {
    body {
      background-color: #110b1b;
    }

    ::-webkit-scrollbar-track {
      background-color: #110b1b;
    }
    ::-webkit-scrollbar-thumb {
      background: #202231;
    }
  }

  .light {
    body {
      background-color: #f0f6fa;
    }

    ::-webkit-scrollbar-track {
      background-color: #f0f6fa;
    }
    ::-webkit-scrollbar-thumb {
      background: #dce2e6;
    }
  }

  ul,
  ol {
    @apply m-4 pl-4;
  }

  .copy {
    p {
      @apply mb-2;
    }
  }
}
